<template>
    <div class="coupon-box" id="coupon-box">
        <title-nav :title="'我的卡券'"/>
        <div class="coupon-list" v-for="(coupon, i) in couponList" :key="i">
          <coupon-card
            :amount="coupon.discount_amount"
            :expirationDate="coupon.expiration_date"
            :code="coupon.coupon_code"
            @goUsedPage="goUsedPage"
            :couponType="coupon.type"
            :couponRange="coupon.range"
            :title="coupon.title"/>
        </div>
        <router-link to="/coupon-more" class="bottom-more">查看历史券包</router-link>
        <!-- <div v-if="loading" class="loading">加载中...</div> -->
        <div v-if="couponList.length === 0 && !loading">
            <div class="none-list">
                <img src="../../assets/images/none.svg" alt="">
                <div class="none">您还没有优惠券喔~</div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryCouponById } from '@/api/api'
import TitleNav from '../../components/nav/TitleNav.vue'
import CouponCard from '../../components/card/CouponCard.vue'

export default {
  name: 'Coupon',
  components: {
    TitleNav,
    CouponCard
  },
  mounted(){
    this.getData('1') // 1-待使用
  },
  data:()=>{
    return {
      couponList:[],
      loading: false
    }
  },
  methods:{
    getData(type){
      let uidTemp = JSON.parse(localStorage.getItem('userInfo'))[0].uid
      this.loading = true
      queryCouponById({ limit: 100, uid: uidTemp, type: type }).then(res => {
        this.loading = false
        res.data.data.forEach(item => {
          let temp = {}
          temp.discount_amount = item.discount_amount
          temp.expiration_date = item.expiration_date
          temp.coupon_code = item.coupon_code
          temp.title = item.title
          temp.range = item.range
          temp.type = item.type === 1 ? 'reduction' : 'discount'
          if(this.$route.query.range){
            if(String(temp.range) === String(this.$route.query.range)){
              this.couponList.push(temp)
            }
          } else {
            this.couponList.push(temp)
          }
        })
      })
    },
    goUsedPage(coupon){
      if(coupon.range === 1){
        if(this.$route.query.promo_code){
          this.$router.push({ path:'/activesvip', query:{ couponCode: coupon.code, promo_code: this.$route.query.promo_code } })
        } else {
          this.$router.push({ path:'/activesvip', query:{ couponCode: coupon.code } })
        }
      } else if(coupon.range === 2){
        if(this.$route.query.promo_code){
          this.$router.push({ path:'/partner', query:{ couponCode: coupon.code, promo_code: this.$route.query.promo_code } })
        } else {
          this.$router.push({ path:'/partner', query:{ couponCode: coupon.code } })
        }
      } else if(coupon.range === 3){
          let fromPath = sessionStorage.getItem('fromPath') ? sessionStorage.getItem('fromPath') : ''
          sessionStorage.setItem('fromPath', 'coupon')
          if(fromPath.includes('cart')){
            if(fromPath.includes('nocartgoods')){
              this.$router.push({ path:'/cart' })
            } else {
              this.$router.push({ path:'/pay', query:{ couponCode: coupon.code } })
            }
          } else if(fromPath.includes('pay') && !this.$route.query.gid){
            this.$router.push({ path:'/pay', query:{ oid: this.$route.query.oid, couponCode: coupon.code } })
          } else {
            if(this.$route.query.oid && this.$route.query.gid){
              this.$router.push({ path:'/pay', query:{ oid: this.$route.query.oid, gid: this.$route.query.gid, couponCode: coupon.code } })
            } else {
              this.$router.push({ path:'/' })
            }
          }
      }
    }
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.coupon-box {
    height: 100%;
    .coupon-list {
      margin: 15px;
    }
}
.none-list {
    margin: 60px auto 20px;
    width: 100%;
    max-width: 625px;
    .none {
        margin: 10px 0 0;
        color: #969699;
    }
    img {
        min-height: 142px;
    }
}
.loading {
    color: #969699;
    margin: 0px auto 20px;
    font-size: 14px;
}
.bottom-more {
  // background: #fff;
  font-size: 13px;
  color: #2c3e50;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  line-height: 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>